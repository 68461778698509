@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-Thin.eot');
    src: local('Roboto Thin'), local('Roboto-Thin'), url('../../fonts/roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Thin.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Thin.woff') format('woff'), url('../../fonts/roboto/Roboto-Thin.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Thin.svg#Roboto') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url('../../fonts/roboto/Roboto-Thin.eot');
    src: local('Roboto Thin'), local('Roboto-Thin'), url('../../fonts/roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Thin.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Thin.woff') format('woff'), url('../../fonts/roboto/Roboto-Thin.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Thin.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-ThinItalic.eot');
    src: local('Roboto ThinItalic'), local('Roboto-ThinItalic'), url('../../fonts/roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-ThinItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-ThinItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-ThinItalic.svg#Roboto') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src: url('../../fonts/roboto/Roboto-ThinItalic.eot');
    src: local('Roboto ThinItalic'), local('Roboto-ThinItalic'), url('../../fonts/roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-ThinItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-ThinItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-ThinItalic.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-Light.eot');
    src: local('Roboto Light'), local('Roboto-Light'), url('../../fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Light.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Light.woff') format('woff'), url('../../fonts/roboto/Roboto-Light.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Light.svg#Roboto') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('../../fonts/roboto/Roboto-Light.eot');
    src: local('Roboto Light'), local('Roboto-Light'), url('../../fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Light.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Light.woff') format('woff'), url('../../fonts/roboto/Roboto-Light.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Light.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-LightItalic.eot');
    src: local('Roboto LightItalic'), local('Roboto-LightItalic'), url('../../fonts/roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-LightItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-LightItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-LightItalic.svg#Roboto') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src: url('../../fonts/roboto/Roboto-LightItalic.eot');
    src: local('Roboto LightItalic'), local('Roboto-LightItalic'), url('../../fonts/roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-LightItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-LightItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-LightItalic.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-Regular.eot');
    src: local('Roboto Regular'), local('Roboto-Regular'), url('../../fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Regular.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Regular.woff') format('woff'), url('../../fonts/roboto/Roboto-Regular.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Regular.svg#Roboto') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../fonts/roboto/Roboto-Regular.eot');
    src: local('Roboto Regular'), local('Roboto-Regular'), url('../../fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Regular.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Regular.woff') format('woff'), url('../../fonts/roboto/Roboto-Regular.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Regular.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-RegularItalic.eot');
    src: local('Roboto RegularItalic'), local('Roboto-RegularItalic'), url('../../fonts/roboto/Roboto-RegularItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-RegularItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-RegularItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-RegularItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-RegularItalic.svg#Roboto') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-RegularItalic';
    src: url('../../fonts/roboto/Roboto-RegularItalic.eot');
    src: local('Roboto RegularItalic'), local('Roboto-RegularItalic'), url('../../fonts/roboto/Roboto-RegularItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-RegularItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-RegularItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-RegularItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-RegularItalic.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../../fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Medium.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Medium.woff') format('woff'), url('../../fonts/roboto/Roboto-Medium.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Medium.svg#Roboto') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../fonts/roboto/Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../../fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Medium.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Medium.woff') format('woff'), url('../../fonts/roboto/Roboto-Medium.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Medium.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-MediumItalic.eot');
    src: local('Roboto MediumItalic'), local('Roboto-MediumItalic'), url('../../fonts/roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-MediumItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-MediumItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-MediumItalic.svg#Roboto') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-MediumItalic';
    src: url('../../fonts/roboto/Roboto-MediumItalic.eot');
    src: local('Roboto MediumItalic'), local('Roboto-MediumItalic'), url('../../fonts/roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-MediumItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-MediumItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-MediumItalic.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'), url('../../fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Bold.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Bold.woff') format('woff'), url('../../fonts/roboto/Roboto-Bold.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Bold.svg#Roboto') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../fonts/roboto/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'), url('../../fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Bold.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Bold.woff') format('woff'), url('../../fonts/roboto/Roboto-Bold.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Bold.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-BoldItalic.eot');
    src: local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url('../../fonts/roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-BoldItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-BoldItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-BoldItalic.svg#Roboto') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-BoldItalic';
    src: url('../../fonts/roboto/Roboto-BoldItalic.eot');
    src: local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url('../../fonts/roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-BoldItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-BoldItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-BoldItalic.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'), url('../../fonts/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Black.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Black.woff') format('woff'), url('../../fonts/roboto/Roboto-Black.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Black.svg#Roboto') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Black';
    src: url('../../fonts/roboto/Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'), url('../../fonts/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-Black.woff2') format('woff2'), url('../../fonts/roboto/Roboto-Black.woff') format('woff'), url('../../fonts/roboto/Roboto-Black.ttf') format('truetype'), url('../../fonts/roboto/Roboto-Black.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/Roboto-BlackItalic.eot');
    src: local('Roboto BlackItalic'), local('Roboto-BlackItalic'), url('../../fonts/roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-BlackItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-BlackItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-BlackItalic.svg#Roboto') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-BlackItalic';
    src: url('../../fonts/roboto/Roboto-BlackItalic.eot');
    src: local('Roboto BlackItalic'), local('Roboto-BlackItalic'), url('../../fonts/roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'), url('../../fonts/roboto/Roboto-BlackItalic.woff') format('woff'), url('../../fonts/roboto/Roboto-BlackItalic.ttf') format('truetype'), url('../../fonts/roboto/Roboto-BlackItalic.svg#Roboto') format('svg');
}
