/* exo-100normal - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 100;
  src: url('./files/exo-latin-100.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Thin '),
    local('Exo-Thin'),
    url('./files/exo-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-100.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-100.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-100italic - latin */
@font-face {
  font-family: 'Exo';
  font-style: italic;
  font-weight: 100;
  src: url('./files/exo-latin-100italic.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Thin italic'),
    local('Exo-Thinitalic'),
    url('./files/exo-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-100italic.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-100italic.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-200normal - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 200;
  src: url('./files/exo-latin-200.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Extra Light '),
    local('Exo-Extra Light'),
    url('./files/exo-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-200.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-200.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-300normal - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 300;
  src: url('./files/exo-latin-300.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Light '),
    local('Exo-Light'),
    url('./files/exo-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-300.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-300.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-200italic - latin */
@font-face {
  font-family: 'Exo';
  font-style: italic;
  font-weight: 200;
  src: url('./files/exo-latin-200italic.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Extra Light italic'),
    local('Exo-Extra Lightitalic'),
    url('./files/exo-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-200italic.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-200italic.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-300italic - latin */
@font-face {
  font-family: 'Exo';
  font-style: italic;
  font-weight: 300;
  src: url('./files/exo-latin-300italic.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Light italic'),
    local('Exo-Lightitalic'),
    url('./files/exo-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-300italic.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-400normal - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  src: url('./files/exo-latin-400.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Regular '),
    local('Exo-Regular'),
    url('./files/exo-latin-400.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-400.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-400.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-400italic - latin */
@font-face {
  font-family: 'Exo';
  font-style: italic;
  font-weight: 400;
  src: url('./files/exo-latin-400italic.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Regular italic'),
    local('Exo-Regularitalic'),
    url('./files/exo-latin-400italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-400italic.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-400italic.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-500normal - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  src: url('./files/exo-latin-500.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Medium '),
    local('Exo-Medium'),
    url('./files/exo-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-500.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-500.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-500italic - latin */
@font-face {
  font-family: 'Exo';
  font-style: italic;
  font-weight: 500;
  src: url('./files/exo-latin-500italic.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Medium italic'),
    local('Exo-Mediumitalic'),
    url('./files/exo-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-500italic.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-500italic.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-600italic - latin */
@font-face {
  font-family: 'Exo';
  font-style: italic;
  font-weight: 600;
  src: url('./files/exo-latin-600italic.eot'); /* IE9 Compat Modes */
  src:
    local('Exo SemiBold italic'),
    local('Exo-SemiBolditalic'),
    url('./files/exo-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-600italic.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-600normal - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  src: url('./files/exo-latin-600.eot'); /* IE9 Compat Modes */
  src:
    local('Exo SemiBold '),
    local('Exo-SemiBold'),
    url('./files/exo-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-600.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-600.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-700normal - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  src: url('./files/exo-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Bold '),
    local('Exo-Bold'),
    url('./files/exo-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-700.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-800normal - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 800;
  src: url('./files/exo-latin-800.eot'); /* IE9 Compat Modes */
  src:
    local('Exo ExtraBold '),
    local('Exo-ExtraBold'),
    url('./files/exo-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-800.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-800.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-700italic - latin */
@font-face {
  font-family: 'Exo';
  font-style: italic;
  font-weight: 700;
  src: url('./files/exo-latin-700italic.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Bold italic'),
    local('Exo-Bolditalic'),
    url('./files/exo-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-700italic.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-800italic - latin */
@font-face {
  font-family: 'Exo';
  font-style: italic;
  font-weight: 800;
  src: url('./files/exo-latin-800italic.eot'); /* IE9 Compat Modes */
  src:
    local('Exo ExtraBold italic'),
    local('Exo-ExtraBolditalic'),
    url('./files/exo-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-800italic.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-900normal - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 900;
  src: url('./files/exo-latin-900.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Black '),
    local('Exo-Black'),
    url('./files/exo-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-900.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-900.svg#exo') format('svg'); /* Legacy iOS */
}

/* exo-900italic - latin */
@font-face {
  font-family: 'Exo';
  font-style: italic;
  font-weight: 900;
  src: url('./files/exo-latin-900italic.eot'); /* IE9 Compat Modes */
  src:
    local('Exo Black italic'),
    local('Exo-Blackitalic'),
    url('./files/exo-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/exo-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/exo-latin-900italic.woff') format('woff'), /* Modern Browsers */
    url('./files/exo-latin-900italic.svg#exo') format('svg'); /* Legacy iOS */
}

