// @nebular theming framework
@import '~@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '~@nebular/theme/styles/themes';

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,

  menu-item-icon-margin: 0 0.5rem 0 0,

  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,

  select-min-width: 6rem,

  slide-out-background: #f7f9fc,
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
), default, default);

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,

  menu-item-icon-margin: 0 0.5rem 0 0,

  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,

  select-min-width: 6rem,

  slide-out-background: #252547,
  slide-out-shadow-color: 2px 0 3px #29157a,
  slide-out-shadow-color-rtl: -2px 0 3px #29157a,
), cosmic, cosmic);

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,

  menu-item-icon-margin: 0 0.5rem 0 0,

  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,

  select-min-width: 6rem,

  slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%),
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
), corporate, corporate);

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,

  menu-item-icon-margin: 0 0.5rem 0 0,

  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,

  select-min-width: 6rem,

  slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
), dark, dark);
